import {createWebHistory, createRouter} from 'vue-router';

import Login from './pages/Login.vue';
import Imprint from './pages/Imprint.vue';

// lazy-loaded
const Home = () => import('./pages/Home.vue');
const Profile = () => import('./pages/Profile.vue');
const Files = () => import('./pages/Files.vue');

const Users = () => import('./pages/Users.vue');
const UserEdit = () => import('./pages/UserEdit.vue');
const UserInsert = () => import('./pages/UserInsert.vue');

const Items = () => import('./pages/Items.vue');
const ItemEdit = () => import('./pages/ItemEdit.vue');

const Clients = () => import('./pages/Clients.vue');
const ClientEdit = () => import('./pages/ClientEdit.vue');
const ClientInsert = () => import('./pages/ClientInsert.vue');

const routes = [
	{
		path: '/',
		component: Login,
	},
	{
		path: '/login',
		component: Login,
	},
	{
		path: '/imprint',
		component: Imprint,
	},
	{
		path: '/home',
		name: 'home',
		// lazy-loaded
		component: Home,
	},
	{
		path: '/profile',
		name: 'profile',
		// lazy-loaded
		component: Profile,
	},
	{
		path: '/users',
		name: 'users',
		// lazy-loaded
		component: Users,
	},
	{
		path: '/user-edit/:token',
		name: 'user-edit',
		// lazy-loaded
		component: UserEdit,
	},
	{
		path: '/user-insert',
		name: 'user-insert',
		// lazy-loaded
		component: UserInsert,
	},
	{
		path: '/files',
		name: 'files',
		// lazy-loaded
		component: Files,
	},
	{
		path: '/items',
		name: 'items',
		// lazy-loaded
		component: Items,
	},
	{
		path: '/item-edit/:id',
		name: 'item-edit',
		// lazy-loaded
		component: ItemEdit,
	},
	{
		path: '/clients',
		name: 'clients',
		// lazy-loaded
		component: Clients,
	},
	{
		path: '/client-edit/:id',
		name: 'client-edit',
		// lazy-loaded
		component: ClientEdit,
	},
	{
		path: '/client-insert',
		name: 'client-insert',
		// lazy-loaded
		component: ClientInsert,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	const publicPages = ['/login', '/imprint'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem('user');

	// trying to access a restricted page + not logged in
	// redirect to login page
	if (authRequired && !loggedIn) {
		next('/login');
	} else {
		next();
	}
});

export default router;
