<template>
	<div class="container mt-5">
		<div class="row">
			<div class="col">
				<h1>Impressum</h1>

				<p>Angaben gemäß § 5 TMG</p>
				<p>
					Gathmann Michaelis und Freunde GbR<br />
					Rosastraße 36<br />
					45130 Essen<br />
				</p>
				<p>
					Vertreten durch<br />
					Geschäftsführung:<br />
					Holger Gathmann<br />
					Andre Michaelis<br />
				</p>
				<p>
					Kontakt<br />
					0201 438708-0<br />
					info@gmf-design.de<br />
				</p>
				<p>
					Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br />
					DE 814814174<br />
				</p>
				<p>
					Angaben zur Berufshaftpflichtversicherung<br />
					Name und Sitz des Versicherers:<br />
					R+V Allgemeine Versicherungs AG<br />
					Raiffeisenplatz 1<br />
					Wiesbaden<br />
				</p>
				<p>
					Streitschlichtung<br />
					Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr.<br />
					Unsere E-Mail-Adresse finden Sie oben im Impressum.<br />
					Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br />
				</p>
			</div>
		</div>
		<div class="d-block mt-5 pt-5"></div>
	</div>
</template>

<script>
export default {
	name: 'AppImprint',
	data() {
		return {
			data: '',
		};
	},
	mounted() {},
};
</script>
